<!--Pagelet showing a list of Blog etc.-->
<template>
  <section class="section has-background-white">
    <div class="container has-text-centered">
      <h1 class="title  is-size-2 has-text-weight-light is-spaced has-space-below">
        {{pageData.title}}
      </h1>
      <h2 class="subtitle is-size-4 has-dbl-space-below">
        {{pageData.subtitle}}
      </h2>
    </div>

    <div class="loading is-loading" v-if="loading">
      Loading...
    </div>

    <div class="notification is-warning has-text-white" v-if="error">
      Blogs cannot be retrieved, error is: {{error}}
    </div>

    <div class="container " v-if="posts.length > 0">

      <div class=" columns is-multiline">
        <div class="column is-one-third" v-for="(post, idx) in posts" :key="idx">

          <div class="card" v-on:click="goto(post.fields.slug)">
            <div class="card-image">
              <figure class="image is-16by9">
                <img class="thumbnail"
                     :src="post.fields.heroImage.fields.file.url + '?fit=scale&w=320&h=180'"
                     :srcset="`${post.fields.heroImage.fields.file.url}?w=320&h=180&fit=fill 320w, ${post.fields.heroImage.fields.file.url}?w=640&h=360&fit=fill 640w, ${post.fields.heroImage.fields.file.url}?w=1280&h=720&fit=fill 1280w`"
                     sizes="(min-width: 1024px) 400px, 100vw"
                >
              </figure>
            </div>
            <div class="card-content">

              <h3 class="is-size-5 has-text-weight-semibold has-space-below">{{post.fields.title}}</h3>

              <div class="content">
                {{post.fields.description}}
              </div>
              <div class="is-pulled-right is-size-7 has-text-info">
                <time :datetime="post.fields.publishDate">{{displayDate(post.fields.publishDate)}}</time>
              </div>

              <div class="tags">
                <span class="tag is-light" v-for="tag in post.fields.tags"
                      :key="tag">
                <router-link
                    :to="{ name: 'blog', query: { tags: tag}}"
                    @click.native="$event.stopImmediatePropagation()"
                >{{ tag }}</router-link>
                </span>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>


  </section>
</template>

<script>
  import misc from '../mixins/misc'

  export default {
    name: 'Blogs',
    mixins: [misc],
    props: {
      // tags: String
    },

    data: function () {
      return {
        loading: false,
        error: null,
        posts: [],
        pageData:
          { //Used as SEO fields too
            title: "Untangl Blog",
            subtitle: "Current thoughts and musings from our team",
          },
      };
    },

    created() {
      this.$log.debug('{Blog} Created');
      this.getBlogs();
    },

    // If the user clicks on a tag the query string changes but the component will not change without this
    beforeRouteUpdate(to,from,next){
      this.$log.debug('{Blog} Route update:', to.query, {});
      this.getBlogs(to.query);
      next();
    },

    //for an individual item we only look at the slug to find it, for a group the tags in query string
    methods: {

      goto: function(slug) {
        this.$log.debug('{Blog} goto called');
        this.$router.push({name:'blogItem', params:{slug:slug}});
      },

      getBlogs: function (params) {
        this.$log.debug('{Blog} getBlogs called');
        this.error = this.post = null;
        this.loading = true;

        let query = {
          skip: 0,
          limit: 10,
          'content_type': process.env.VUE_APP_ARTICLE_TYPE_ID,
          'fields.type': process.env.VUE_APP_BLOG_TYPE,
          order: '-fields.publishDate'  //'-sys.createdAt' -sys.updatedAt
        };
        if (params && params.tags){
          query['fields.tags'] = params.tags;
        }
        // https://www.contentful.com/developers/docs/references/content-management-api/#/introduction/collection-resources-and-pagination

        this.$contentful
          .getEntries(query)
          .then((res) => {
            this.posts = res.items;
            this.loading = false;
            // this.$log.debug('{Blog} getBlogs result:', JSON.stringify(this.posts[0].fields.heroImage, null, 2));
          })
          .catch((error) => {
            this.$log.debug('{Blog} getBlogs failed with error:', JSON.stringify(error, null, 2));
            this.loading = false;
            this.error = error;
          })
      }
    },

    // For Blog page we use title and subtitle for SEO
    metaInfo() {
      return {
        title: this.pageData.title,
        meta: [
          {
            property: 'og:title',
            content: this.pageData.title,
            vmid: 'og:title'
          },
          {
            property: 'og:description',
            content: this.pageData.subtitle,
            vmid: 'og:description'
          },
          { vmid: 'description',
            name: 'description',
            content: this.pageData.subtitle},
          { vmid: 'robots',
            name: 'robots',
            content: 'index, follow'} //noindex
        ]
      }
    }


  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

  /*
    @import "~bulma/sass/utilities/mixins";
    @import "@/scss/_bulmaOverridden";
  */
.card {
  cursor: pointer;
}

</style>
